import { patch } from "@rails/request.js"
import Sortable from "sortablejs"
import { leafClass } from "@javascript/controllers/tree_controller/tree_controller"

export default class Sorter {
  constructor(controller) {
    this.controller = controller
  }

  init() {
    this.#initRoot()
    this.controller.roots.forEach((root) => this.#initDescendants(root))
  }

  #initDescendants(origin) {
    this.#sortableChildren(origin).forEach((node) => {
      this.#initSortable(node)
    })
  }

  #sortableChildren(node) {
    const ul = Array.from(node.children).find((child) => child.matches("ul"))
    return ul ? [ul].concat(Array.from(ul.children).flatMap((child) => this.#sortableChildren(child))) : []
  }

  #initRoot() {
    this.#initSortable(this.controller.element)
  }

  #initSortable(node) {
    return new Sortable(node, {
      animation: 250,
      onEnd: this.#onEnd.bind(this),
      group: {
        name: "sortable",
      },
      fallbackOnBody: false,
      swapThreshold: 0.75,
      invertSwap: true,
    })
  }

  #onEnd(evt) {
    this.#patchPosition(evt)
    this.#removeLeafClass(evt)
  }

  #removeLeafClass({ to, from }) {
    to.closest("li")?.classList.remove(leafClass)
    const closestNode = from.closest("li")
    if (closestNode && this.controller.children(closestNode).length === 0) {
      closestNode.classList.add(leafClass)
    }
  }

  #patchPosition({ item, to, newIndex }) {
    const { sorterUpdateUrl } = item.dataset
    if (!sorterUpdateUrl) return

    const params = {}
    // If no parentId is defined, we'd still like to send it to the backend to cover the case when a leaf becomes a root
    params[this.controller.parentParamNameValue] = to.dataset.sorterParentId ?? null
    params[this.controller.indexParamNameValue] = newIndex + 1

    const body = this.controller.resourceNameValue ? { [this.controller.resourceNameValue]: params } : params

    patch(sorterUpdateUrl, { body: JSON.stringify(body), responseKind: this.controller.responseKindValue })
  }
}
