import { Controller } from "@hotwired/stimulus"
import Tagify from "@yaireo/tagify"
import { debounce } from "lodash"

export default class TagifyController extends Controller {
  static values = {
    url: String,
    tagTextProp: {
      type: String,
      default: "value",
    },
    delimiters: {
      type: String,
      default: ",| ",
    },
    whitelist: Array,
  }

  set #whitelist(tags) {
    this.tagify.whitelist = tags.map((tag) => tag[this.tagTextPropValue])
  }

  connect() {
    this.tagify = new Tagify(this.element, {
      whitelist: this.whitelistValue,
      delimiters: this.delimitersValue,
      tagTextProp: this.tagTextPropValue,
      originalInputValueFormat: this.#stringifyInputValue,
      transformTag: (tagData) => {
        // Remove any "#" from the beginning of the string
        const value = this.#stripLeadingHash(tagData.value)
        if (this.tagTextPropValue && this.tagTextPropValue !== "value") {
          tagData[this.tagTextPropValue] = value
        }
        tagData.value = value
      },
      editTags: false,
      a11y: { focusableTags: true },
      // Search happens on the server, prevent further filtering on the frontend
      dropdown: { searchKeys: [] },
    })
    this.tagify.on("input", debounce(this.onInput.bind(this), 300))
  }

  #stringifyInputValue(values) {
    return values.map((item) => item.value).join(",")
  }

  async onInput(e) {
    let value = this.#stripLeadingHash(e.detail.value)

    this.#resetWhitelist()

    const response = await this.fetchTags(value)
    this.#whitelist = response
    this.tagify.dropdown.show(value)
  }

  async fetchTags(query) {
    const response = await fetch(`${this.urlValue}?query=${query}`)
    return await response.json()
  }

  #resetWhitelist() {
    this.tagify.whitelist = null
  }

  #stripLeadingHash(string) {
    return string.replace(/^#/, "")
  }
}
