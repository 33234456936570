import { Controller } from "@hotwired/stimulus"

export default class FormController extends Controller {
  static targets = ["cancel"]

  submit() {
    this.element.requestSubmit()
  }

  reset() {
    this.element.reset()
  }

  cancel() {
    this.cancelTarget?.click()
  }
}
