export default class Keyboard {
  constructor(controller) {
    this.controller = controller
  }

  get visibleToggles() {
    let nodes = this.controller.visibleNodes.filter((node) => !this.controller.isLeaf(node))
    return nodes.map((node) => this.controller.findToggle(node))
  }

  keydown(e) {
    if (!this.controller.isToggle(e.target)) return

    let node = e.target.closest("li")

    switch (e.code) {
      case "ArrowLeft":
        this.moveLeft(node)
        e.preventDefault()
        break
      case "ArrowUp":
        this.moveUp(node)
        e.preventDefault()
        break
      case "ArrowRight":
        this.moveRight(node)
        e.preventDefault()
        break
      case "ArrowDown":
        this.moveDown(node)
        e.preventDefault()
        break
    }
  }

  moveUp(node) {
    let toggles = this.visibleToggles
    let index = toggles.indexOf(this.controller.findToggle(node)) - 1
    if (index >= 0 && toggles[index]) toggles[index].focus()
  }

  moveDown(node) {
    let toggles = this.visibleToggles
    let index = toggles.indexOf(this.controller.findToggle(node)) + 1
    if (index >= 0 && toggles[index]) toggles[index].focus()
  }

  moveRight(node) {
    if (this.controller.isOpened(node)) {
      let child = this.controller.children(node)[0]
      if (child) {
        let toggle = this.controller.findToggle(child)
        if (toggle) toggle.focus()
      }
    } else {
      this.controller.open(node)
    }
  }

  moveLeft(node) {
    if (!this.controller.isOpened(node)) {
      let parent = this.controller.parent(node)
      if (parent) {
        let toggle = this.controller.findToggle(parent)
        if (toggle) toggle.focus()
      }
    } else {
      this.controller.close(node)
    }
  }
}
